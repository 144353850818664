import { useAuth } from 'react-oidc-context';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import Notification from '@sonar-web/common/src/components/Notification/Notification';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
import useCurrentTenant from '../Hooks/useCurrentTenant';

const Logout = () => {
	const { translate } = useLocale();
	const { removeCurrentTenant } = useCurrentTenant();
	const auth = useAuth();

	const handleLogout = async () => {
		try {
			removeCurrentTenant();
			await auth.signoutRedirect();
			await auth.removeUser();
		} catch (error) {
			console.error('Logout error:', error);
			Notification.error({ message: translate('LogoutError') });
		}
	};

	return (
		<BaseButton onClick={handleLogout} variant='outlined'>
			{translate('Logout')}
		</BaseButton>
	);
};

export default Logout;

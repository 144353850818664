import { Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';

const TableListColumnHeader = ({ text, children, ...rest }) => {
	const { translate } = useLocale();

	if (children) return children;
	return (
		<Typography variant='caption' color='textSecondary' {...rest}>
			{translate(text)}
		</Typography>
	);
};

export default TableListColumnHeader;

import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.WMBUSHUB24;

async function fetchWmbusHubs(pageDescriptor) {
	const { data } = await apiBase(`wmbushubs`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

async function addWmbusHub(requestData) {
	const response = await apiBase(`wmbushub`, 'post', {
		path: path,
		data: requestData
	});

	const locationHeader = response.headers.location;
	if (locationHeader) return locationHeader.slice(locationHeader.indexOf('/') + 1);
	return null;
}

async function fetchWmbusHub(id) {
	const { data } = await apiBase(`wmbushub/${id}`, 'get', {
		path
	});

	return data;
}

async function fetchWmbusHubConfigurationData(id) {
	const { data } = await apiBase(`wmbushub/${id}/configurationData`, 'get', {
		path
	});

	return data;
}

async function fetchHubDiagnostic(pageDescriptor) {
	const { data } = await apiBase(`wmbushub/diagnosticReads`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchWmbusKnownDevices(pageDescriptor, { id }) {
	const { data } = await apiBase(`wmbushub/${id}/knownDevices`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

async function fetchWmbusUnknownDevices(pageDescriptor, { id }) {
	const { data } = await apiBase(`wmbushub/${id}/unknownDevices`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

const WmbusHubsService = {
	fetchWmbusHubs,
	addWmbusHub,
	fetchWmbusHub,
	fetchWmbusHubConfigurationData,
	fetchHubDiagnostic,
	fetchWmbusKnownDevices,
	fetchWmbusUnknownDevices
};

export default WmbusHubsService;

import { Typography, Box } from '@mui/material';
import { TableListItem, formatDateTimeSecondsPrecision } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';

const ConverterDiagnosticListItem = ({ row }) => {
	const data = !row.diagnosticData ? null : JSON.parse(row.diagnosticData);

	return (
		<TableListItem
			classNames={classNameByStatus(row.status)}
			listItemProps={{
				sx: {
					'&.tablelistitem': {
						borderLeftWidth: '5px',
						borderLeftStyle: 'solid',
						borderLeftColor: 'border.main',
						'&.status_connected': {
							borderLeftColor: 'success.main'
						},
						'&.status_disconnected': {
							borderLeftColor: 'error.main'
						}
					}
				}
			}}>
			<Box>{!data ? <NoDiagnosticData date={row.date} /> : <DiagnosticData data={data} date={row.date} />}</Box>
		</TableListItem>
	);
};

export default ConverterDiagnosticListItem;

const NoDiagnosticData = ({ date }) => {
	const { translate } = useLocale();

	return (
		<>
			<Typography variant='body2'>
				{`${formatDateTimeSecondsPrecision(date)} | ${translate('Stationary_LegendConverterDisconnected')}`}
			</Typography>
			<Typography variant='body2' color='textSecondary'>
				{translate('Stationary_ConverterNoDiagnosticDataText')}
			</Typography>
		</>
	);
};

const DiagnosticData = ({ data, date }) => {
	const { translate } = useLocale();

	return (
		<>
			<Typography variant='body2'>
				{`${formatDateTimeSecondsPrecision(date)} | ${translate('Stationary_LegendConverterConnected')}`}
			</Typography>
			<Typography
				component='span'
				variant='body2'
				color='textSecondary'
				sx={{
					'& > span:after': {
						content: '" | "'
					},
					'& > span:last-child:after': {
						content: '""'
					}
				}}>
				{data.Lac != null && (
					<span>
						{translate('Lac')} {data.Lac}
					</span>
				)}
				{data.Cid != null && (
					<span>
						{translate('Cid')} {data.Cid}
					</span>
				)}
				{data.Rssi != null && (
					<span>
						{translate('Rssi')} {data.Rssi} {translate('Dbm')}
					</span>
				)}
				{data.Ip != null && (
					<span>
						{translate('Ip')} {data.Ip}
					</span>
				)}
				{data.MacAddress != null && (
					<span>
						{translate('MacAddress')} {data.MacAddress}
					</span>
				)}
			</Typography>
		</>
	);
};

const classNameByStatus = (status) => `status_${status?.toLowerCase()}`;

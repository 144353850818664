import { createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchDevice, addDevice, editDevice, deleteDevice } from './deviceApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { guidEmpty } from '@sonar-web/common';

const slice = 'wmbusDevice';
const getDevice = (getState) => getState().wmbusDevice.device;

const initialDeviceData = {
	id: guidEmpty,
	number: '',
	deviceTypeId: '',
	deviceTypeName: ''
};

export const deviceSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		addSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		deletePending: false,
		deleteSuccess: false,
		device: initialDeviceData
	},
	reducers: {
		fetchDeviceSucces: (state, action) => {
			state.device = action.payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchDevicePending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		addDeviceSucces: (state, action) => {
			state.addPending = false;
			state.addSuccess = action.payload;
		},
		addDevicePending: (state, action) => {
			state.addPending = action.payload ?? true;
		},
		deleteDeviceSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteDevicePending: (state, action) => {
			state.deletePending = action.payload ?? true;
		},
		setDeviceData: (state, action) => {
			state.device = action.payload;
		},
		resetDeviceData: (state, action) => {
			const { isInSubmitManyMode } = action.payload || {};

			state.device = isInSubmitManyMode
				? {
						...initialDeviceData,
						deviceTypeId: state.device.deviceTypeId,
						deviceTypeName: state.device.deviceTypeName
					}
				: initialDeviceData;
			state.addSuccess = false;
			state.deleteSuccess = false;
			state.fetchSuccess = false;
		},
		resetAddSuccess: (state) => {
			state.addSuccess = false;
		}
	}
});

export const {
	fetchDeviceSucces,
	fetchDevicePending,
	addDeviceSucces,
	addDevicePending,
	deleteDeviceSucces,
	deleteDevicePending,
	setDeviceData,
	resetDeviceData,
	resetAddSuccess
} = deviceSlice.actions;

export const fetchDeviceAsync = (id) => async (dispatch) => {
	let response;

	try {
		dispatch(fetchDevicePending());
		response = await fetchDevice(id);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchDeviceSucces(response));
};

export const addDeviceAsync = () => async (dispatch, getState) => {
	const device = { ...getDevice(getState) };
	let response;
	let result = Object.assign({}, device);

	delete device.deviceTypeName;

	try {
		dispatch(addDevicePending());

		response = await addDevice(device);
	} catch (error) {
		dispatch(addDevicePending(false));

		const errorResult = await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));

		return errorResult;
	}

	result.id = response.id;

	dispatch(addDeviceSucces(response.id ? result : true));
};

export const editDeviceAsync = () => async (dispatch, getState) => {
	const device = getDevice(getState);

	try {
		dispatch(addDevicePending());
		await editDevice(device);
	} catch (error) {
		dispatch(addDevicePending(false));

		const errorResult = await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));

		return errorResult;
	}

	dispatch(addDeviceSucces(true));
};

export const deleteDeviceAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteDevicePending());
		await deleteDevice(id);
		dispatch(deleteDeviceSucces());
	} catch (error) {
		dispatch(deleteDevicePending(false));
		const errorResult = await dispatch(addErrorAsync({ slice, error }));

		return errorResult;
	}
};

export const selectDevice = createSelector(
	(state) => state[slice],
	(stateSlice) => ({
		dataRow: stateSlice.device,
		fetchPending: stateSlice.fetchPending,
		fetchSuccess: stateSlice.fetchSuccess
	})
);

export const selectDevicePending = createSelector(
	(state) => state[slice],
	(stateSlice) => stateSlice.addPending
);

export const selectDeviceFetchPending = createSelector(
	(state) => state[slice],
	(stateSlice) => stateSlice.fetchPending
);

export const selectDeviceAddSuccess = createSelector(
	(state) => state[slice],
	(stateSlice) => stateSlice.addSuccess
);

export const selectDeleteDevicePending = createSelector(
	(state) => state[slice],
	(stateSlice) => ({
		deletePending: stateSlice.deletePending,
		deleteSuccess: stateSlice.deleteSuccess
	})
);

export default deviceSlice.reducer;

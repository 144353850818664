/* eslint-disable react/display-name */
import { useEffect, useState, forwardRef, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import { Typography } from '@mui/material';
import { BaseLinearProgress, BaseCircularProgress, ListActionToolbar, PanelList } from '@sonar-web/common';
import useListSorting from '@sonar-web/common/src/components/Sorters/useListSorting';
import { useLocale } from '@sonar-web/common/src/hooks';
import useList from '@sonar-web/common/src/features/List/useList';
import { fetchThunk, setOffset } from '@sonar-web/common/src/features/List/genericListSlice';
import EmptyPlaceholder from '@sonar-web/common/src/components/EmptyPlaceholder/EmptyPlaceholder';
import TerminalDiagnosticReadsListItem from './TerminalDiagnosticReadsListItem';
import { slices } from '../../../Constants/Module';
import { fetchDiagnosticTerminalReads } from '../terminalDiagnosticApi';

const slice = slices.terminalDiagnostic;

const TerminalDiagnosticReadsList = ({ deviceId, networkId }) => {
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const { listSelector } = useList(slice);
	const [isReloading, setIsReloading] = useState(true);
	const virtuoso = useRef(null);

	const { elements, hasMore, pending, totalCount } = listSelector;
	const elementsCount = elements?.length;

	const fetchData = (append = false) => {
		if (append) setIsReloading(!append);

		dispatch(setOffset(slice)(append));
		dispatch(
			fetchThunk(slice)({ endpoint: fetchDiagnosticTerminalReads, endpointConfig: { deviceId, networkId } })
		);
	};

	useListSorting({ slice, callback: fetchData });

	useEffect(() => {
		fetchData();
	}, [networkId]);

	if (!elements) return null;
	return (
		<>
			<ListActionToolbar slice={slice} pending={pending} sorters={sorters} totalCount={totalCount}>
				<Typography variant='body2' fontWeight='medium'>
					{translate('Wmbus_DetailedScheduleOfDeviceCommunication')}
				</Typography>
			</ListActionToolbar>
			<BaseLinearProgress pending={pending && isReloading} />
			<Virtuoso
				ref={virtuoso}
				useWindowScroll
				customScrollParent={document.getElementById('contentWrapper')}
				data={elements}
				context={{ pending, isReloading }}
				components={{ List, EmptyPlaceholder: Placeholder, Footer }}
				itemContent={ListItem}
				totalCount={elementsCount}
				endReached={() => {
					if (hasMore) fetchData(true);
				}}
			/>
		</>
	);
};
export default TerminalDiagnosticReadsList;

const List = forwardRef(({ style, children }, listRef) => {
	return (
		<PanelList id='TerminalDiagnosticReadsList' ref={listRef} style={style} disablePadding>
			{children}
		</PanelList>
	);
});

const ListItem = (_, item) => <TerminalDiagnosticReadsListItem row={item} />;

const Placeholder = () => <EmptyPlaceholder text='Wmbus_WmbusDeviceDiagnosticReadsListEmpty' fullHeight />;

const Footer = ({ context }) => {
	const { pending, isReloading } = context;
	return <BaseCircularProgress pending={pending && !isReloading} />;
};

const sorters = {
	initial: 0,
	sorterData: [
		{
			member: 'networkReadDate',
			text: 'Stationary_ReadDate',
			direction: 'desc',
			directionText: 'Common_Latest'
		},
		{
			member: 'networkReadDate',
			text: 'Stationary_ReadDate',
			direction: 'asc',
			directionText: 'Common_Oldest'
		}
	]
};

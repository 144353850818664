import styled from '@emotion/styled';
import clsx from 'clsx';

const Header = styled('div')(({ theme }) => ({
	position: 'sticky',
	top: 0,
	zIndex: 2,
	display: 'flex',
	backgroundColor: theme.palette.background.default,
	padding: '0 16px 10px',
	'&.withScroll': {
		marginRight: 26
	},
	'&.withLeftBorder': {
		paddingLeft: 20
	}
}));

const TableListHeader = ({ id, children, withLeftBorder = false, ...rest }) => {
	return (
		<Header id={id} className={clsx({ withLeftBorder })} {...rest}>
			{children}
		</Header>
	);
};

export default TableListHeader;

import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { fetchDiagnosticTerminalReads } from '../terminalDiagnosticApi';

const slice = 'terminalDiagnosticReadsTimeline';

export const terminalDiagnosticReadsTimelineSlice = createSlice({
	name: slice,
	initialState: {
		pending: false,
		reset: false,
		reads: [],
		networkScheduledReadDate: null
	},
	reducers: {
		fetchTerminalDiagnosticReadsTimelineSucces: (state, action) => {
			const { elements, networkScheduledReadDate } = action.payload;

			state.reads = elements;
			state.networkScheduledReadDate = networkScheduledReadDate;
			state.pending = false;
			state.reset = false;
		},
		fetchTerminalDiagnosticReadsTimelinePending: (state, action) => {
			state.pending = action.payload;
		},
		setResetAndReload: (state) => {
			state.reset = true;
		}
	}
});

export const {
	fetchTerminalDiagnosticReadsTimelineSucces,
	fetchTerminalDiagnosticReadsTimelinePending,
	setResetAndReload
} = terminalDiagnosticReadsTimelineSlice.actions;

export const fetchTerminalDiagnosticReadsTimelineAsync =
	({ deviceId, networkId }) =>
	async (dispatch) => {
		try {
			const pageDescriptor = null;

			dispatch(fetchTerminalDiagnosticReadsTimelinePending(true));
			const response = await fetchDiagnosticTerminalReads(pageDescriptor, {
				networkId,
				deviceId
			});

			dispatch(fetchTerminalDiagnosticReadsTimelineSucces(response));
		} catch (error) {
			dispatch(fetchTerminalDiagnosticReadsTimelinePending(false));
			dispatch(addErrorAsync({ slice, error }));
		}
	};

export const selectTerminalDiagnosticReadsTimeline = createSelector(
	(state) => state[slice],
	(stateSlice) => ({
		reads: stateSlice.reads,
		networkScheduledReadDate: stateSlice.networkScheduledReadDate,
		pending: stateSlice.pending,
		reset: stateSlice.reset
	})
);

export default terminalDiagnosticReadsTimelineSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatDateTimeSecondsPrecision, BaseLinearProgress, guidEmpty } from '@sonar-web/common';
import TimelineTopIndicator from './TimelineTopIndicator';
import { useLocale } from '@sonar-web/common/src/hooks';
import TimelinePoint from './TimelinePoint';
import TimelineBottomContent from './TimelineBottomContent';
import { readSucceededColor, readFailedColor, largeDotSize, timelineHeight } from './constants';
import Connector from './Connector';
import TimelinePeriod from './TimelinePeriod';
import {
	selectTerminalDiagnosticReadsTimeline,
	fetchTerminalDiagnosticReadsTimelineAsync
} from './terminalDiagnosticReadsTimelineSlice';

const findLastIndex = (array, fn) => {
	if (!array) return -1;
	return array.reduceRight((prev, currentValue, currentIndex) => {
		if (prev > -1) return prev;
		if (fn(currentValue, currentIndex)) return currentIndex;
		return -1;
	}, -1);
};

const useStyles = makeStyles((theme) => ({
	timelineTopIndicator: {
		marginRight: -5,
		marginLeft: -5
	},
	primaryText: {
		padding: 0,
		margin: '0 2px',
		color: theme.palette.text.primary
	},
	secondaryText: {
		padding: 0,
		margin: '0 2px',
		color: theme.palette.text.secondary
	},
	arrowRight: {
		width: 0,
		height: 0,
		borderTop: '5px solid transparent',
		borderBottom: '5px solid transparent',
		borderLeft: `10px solid ${theme.palette.grey[400]}`,
		color: theme.palette.grey[400]
	}
}));

const DeviceDiagnosticReadsTimeline = ({ deviceId, networkId }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const theme = useTheme();

	const dispatch = useDispatch();
	const { reads, pending, networkScheduledReadDate } = useSelector(selectTerminalDiagnosticReadsTimeline);

	const [state, setState] = useState(null);

	useEffect(() => {
		dispatch(fetchTerminalDiagnosticReadsTimelineAsync({ deviceId, networkId }));
	}, [deviceId, networkId]);

	useEffect(() => {
		if (!reads) return;

		const indexOfLastGoodRead = findLastIndex(reads, (read) => read.isSuccessfullRead);

		const historicReads =
			indexOfLastGoodRead == undefined || indexOfLastGoodRead <= 0 ? [] : reads.slice(0, indexOfLastGoodRead);

		const lastSuccessfulCommunication =
			indexOfLastGoodRead == undefined || indexOfLastGoodRead < 0 ? null : reads[indexOfLastGoodRead];

		const lastFailedCommunication =
			reads?.length > 0 && indexOfLastGoodRead != reads.length - 1 ? reads[reads.length - 1] : null;

		const lastFailedReadsHistory =
			indexOfLastGoodRead + 1 < reads.length - 1 ? reads.slice(indexOfLastGoodRead + 1, reads.length - 1) : [];

		const nextScheduledCommunicationDate = networkScheduledReadDate;

		let lastSuccessfulCommunicationText = lastSuccessfulCommunication
			? `${moment(lastSuccessfulCommunication.terminalReadDate).fromNow()}`
			: null;

		let nextScheduledCommunicationDateText = nextScheduledCommunicationDate
			? `${moment(nextScheduledCommunicationDate).fromNow()}`
			: null;

		setState({
			reads,
			historicReads,
			lastFailedCommunication,
			lastFailedReadsHistory,
			lastSuccessfulCommunication,
			nextScheduledCommunicationDate,
			lastSuccessfulCommunicationText,
			nextScheduledCommunicationDateText,
			indexOfLastGoodRead
		});
	}, [reads]);

	if (!state) return null;
	return (
		<>
			<BaseLinearProgress pending={pending} />
			{!pending && (
				<Box display='flex' flexDirection='column'>
					<Box display='flex' flexDirection='column' mt={2}>
						<Box display='flex' flexDirection='row' flex={1} mb={12} alignItems='flex-end'>
							{/* Odczyty historyczne: */}
							{(state.lastSuccessfulCommunication || state.lastFailedCommunication) && (
								<Box display='flex' flexDirection='column' flexGrow={4}>
									<TimelineTopIndicator
										className={classes.timelineTopIndicator}
										leftSideOpen={true}
										minWidth={200}>
										<Typography
											variant='caption'
											classes={{
												root: classes.secondaryText
											}}>
											{translate('Wmbus_HistoryOfRecentCommunications')}
										</Typography>
									</TimelineTopIndicator>
									<TimelinePeriod
										reads={
											state.lastSuccessfulCommunication
												? state.historicReads
												: state.lastFailedReadsHistory
										}
										overflowLeft={true}
									/>
								</Box>
							)}

							{/* ostatni dobry odczyt: */}
							{state.lastSuccessfulCommunication && (
								<Box display='flex' flexDirection='column' justifyContent='flex-end' height='100%'>
									<TimelineBottomContent
										text={translate('Wmbus_LastSuccessfulCommunication')}
										textColor={readSucceededColor}
										tooltipText={formatDateTimeSecondsPrecision(
											state.lastSuccessfulCommunication.terminalReadDate ??
												state.lastSuccessfulCommunication.networkReadDate
										)}
										tooltipBackground={
											state.indexOfLastGoodRead == state.reads.length - 1
												? readSucceededColor
												: 'white'
										}
										tooltipTextColor={
											state.indexOfLastGoodRead == state.reads.length - 1 ? 'white' : 'black'
										}
										tooltipBorder={`1px solid ${
											state.indexOfLastGoodRead == state.reads.length - 1
												? readSucceededColor
												: theme.palette.grey[200]
										}`}>
										<Box minHeight={timelineHeight} display='flex' alignItems='center'>
											<TimelinePoint
												size={largeDotSize}
												doubleBorderDot={state.indexOfLastGoodRead == state.reads.length - 1}
												color={readSucceededColor}
											/>
										</Box>
									</TimelineBottomContent>
								</Box>
							)}

							{/* ostatnie nieudane odczyty od ostatnie udanej komunikacji: */}
							{state.lastSuccessfulCommunication &&
								state.lastFailedCommunication &&
								state.lastFailedReadsHistory && (
									<Box display='flex' flexDirection='column' flexGrow={4}>
										<TimelineTopIndicator className={classes.timelineTopIndicator} minWidth={200}>
											<Typography
												variant='caption'
												classes={{
													root: classes.secondaryText
												}}>
												{translate('Wmbus_TimeElapsedSinceLastSuccessfulCommunication')}{' '}
											</Typography>
											<Typography
												variant='caption'
												classes={{
													root: classes.primaryText
												}}>
												{state.lastSuccessfulCommunicationText}
											</Typography>
										</TimelineTopIndicator>
										<TimelinePeriod reads={state.lastFailedReadsHistory} overflowRight={true} />
									</Box>
								)}

							{/* ostatnia próba komunikacji: */}
							{state.lastFailedCommunication && (
								<Box display='flex' flexDirection='column' justifyContent='flex-end' height='100%'>
									<TimelineBottomContent
										text={translate('Wmbus_LastFailedCommunication')}
										textColor={readFailedColor}
										tooltipText={formatDateTimeSecondsPrecision(
											state.lastFailedCommunication.terminalReadDate ??
												state.lastFailedCommunication.networkReadDate
										)}
										tooltipBackground={readFailedColor}
										tooltipTextColor='white'
										tooltipBorder={`1px solid ${readFailedColor}`}>
										<Box minHeight={timelineHeight} display='flex' alignItems='center'>
											<TimelinePoint
												size={largeDotSize}
												doubleBorderDot={true}
												color={readFailedColor}
											/>
										</Box>
									</TimelineBottomContent>
								</Box>
							)}

							{state.nextScheduledCommunicationDate && (
								<>
									<Box display='flex' flexDirection='column' flexGrow={2}>
										<TimelineTopIndicator
											leftSideOpen={
												!state.lastSuccessfulCommunication && !state.lastFailedCommunication
											}
											className={classes.timelineTopIndicator}
											minWidth={240}>
											<Box>
												<Typography
													variant='caption'
													classes={{
														root: classes.secondaryText
													}}>
													{translate('Wmbus_NextPlannedDateAfter')}{' '}
												</Typography>
											</Box>
											<Box>
												<Typography
													variant='caption'
													classes={{
														root: classes.primaryText
													}}>
													{state.nextScheduledCommunicationDateText}
												</Typography>
											</Box>
										</TimelineTopIndicator>
										<Box minHeight={timelineHeight} display='flex' alignItems='center'>
											<Connector />
										</Box>
									</Box>

									<Box display='flex' flexDirection='column' justifyContent='flex-end' height='100%'>
										<TimelineBottomContent
											text={translate('Wmbus_NextScheduledCommunication')}
											textColor={theme.palette.grey[500]}
											tooltipText={formatDateTimeSecondsPrecision(
												state.nextScheduledCommunicationDate
											)}
											tooltipBackground='white'
											tooltipTextColor='black'
											tooltipBorder={`1px solid ${theme.palette.grey[200]}`}>
											<Box minHeight={timelineHeight} display='flex' alignItems='center'>
												<TimelinePoint size={largeDotSize} color={theme.palette.grey[400]} />
											</Box>
										</TimelineBottomContent>
									</Box>
								</>
							)}
							<Box display='flex' flexDirection='column' justifyContent='flex-end' flexGrow={1}>
								<Box minHeight={timelineHeight} display='flex' alignItems='center' width='100%'>
									<Connector minWidth={20} width='100%' />
									<Box className={classes.arrowRight}></Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};

export default DeviceDiagnosticReadsTimeline;

import React from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	textTooltip: (props) => ({
		backgroundColor: 'transparent',
		fontSize: theme.typography.pxToRem(12),
		fontWeight: theme.typography.normal,
		color: props.textColor
	}),
	tooltip: (props) => ({
		marginTop: '50px !important',
		backgroundColor: props.tooltipBackground,
		fontSize: theme.typography.pxToRem(14),
		fontWeight: theme.typography.fontWeightLight,
		padding: 10,
		borderRadius: 8,
		color: props.tooltipTextColor,
		border: props.tooltipBorder
	}),
	tooltipPopper: {
		zIndex: 1
	},
	tooltipArrow: (props) => ({
		color: props.tooltipBackground,
		'&:before': {
			border: props.tooltipBorder
		}
	})
}));

const TimelineBottomContent = ({
	children,
	text,
	tooltipText,
	textColor,
	tooltipBackground,
	tooltipTextColor,
	tooltipBorder
}) => {
	const classes = useStyles({
		textColor,
		tooltipBackground,
		tooltipTextColor,
		tooltipBorder
	});

	return (
		<Tooltip
			title={text}
			placement='bottom'
			open={true}
			classes={{ tooltip: classes.textTooltip, popper: classes.tooltipPopper }}
			zindex={1}>
			<div>
				<Tooltip
					zindex={1}
					title={tooltipText}
					arrow
					placement='bottom'
					open={true}
					classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip, popper: classes.tooltipPopper }}>
					{children}
				</Tooltip>
			</div>
		</Tooltip>
	);
};

export default TimelineBottomContent;

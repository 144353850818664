import React from 'react';
import { ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	listItemBase: {
		minHeight: 37
	},
	selected: {
		backgroundColor: theme.palette.primaryLight.main
	},
	noBottomBorder: {
		borderBottomColor: 'transparent'
	},
	bottomBorder: {
		borderBottomColor:
			theme.palette.mode === 'light'
				? `${theme.palette.grey[300]} !important`
				: `${theme.palette.grey[800]} !important`
	},
	hoverBehavior: {
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.palette.primaryLight.light,
			'& .linkHoverIcon': {
				visibility: 'visible',
				opacity: 1,
				marginLeft: 5,
				width: 'auto'
			}
		}
	}
}));

const ListItemBase = ({
	children,
	hideBorder,
	showBorder,
	hideContainerBorder,
	hoverBehavior,
	className = '',
	selected = false,
	...rest
}) => {
	const classes = useStyles();

	return hideContainerBorder ? (
		<ListItem
			dense
			{...rest}
			classes={{
				container: clsx({ [classes.noBottomBorder]: hideContainerBorder })
			}}
			className={clsx(`${classes.listItemBase} ${className}`, {
				[classes.noBottomBorder]: hideBorder,
				[classes.bottomBorder]: showBorder,
				[classes.hoverBehavior]: hoverBehavior,
				[classes.selected]: selected
			})}>
			{children}
		</ListItem>
	) : (
		<ListItem
			dense
			{...rest}
			className={clsx(`${classes.listItemBase} ${className}`, {
				[classes.noBottomBorder]: hideBorder,
				[classes.bottomBorder]: showBorder,
				[classes.hoverBehavior]: hoverBehavior,
				[classes.selected]: selected
			})}>
			{children}
		</ListItem>
	);
};

export default ListItemBase;

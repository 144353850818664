import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { usePrevious } from '@sonar-web/common/src/hooks';
import { BaseLinearProgress, guidEmpty, CustomSelectInlineText } from '@sonar-web/common';
import { Box, Typography } from '@mui/material';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
import EmptyPlaceholder from '@sonar-web/common/src/components/EmptyPlaceholder/EmptyPlaceholder';
import DeviceDiagnosticReadsTimeline from './DiagnosticReadsTimeline/DeviceDiagnosticReadsTimeline';
import TerminalDiagnosticReadsList from './DiagnosticReadsList/TerminalDiagnosticReadsList';
import { fetchTerminalNetworks } from './terminalDiagnosticApi';

const TerminalDiagnostic = ({ deviceId }) => {
	const { translate } = useLocale();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [networks, setNetworks] = useState([]);
	const [selectedNetwork, setSelectedNetwork] = useState(null);
	const [fetchNetworksPending, setFetchNetworksPending] = useState(false);
	const previousDeviceId = usePrevious(deviceId);

	const fetchNetworksAsync = (params) => async () => {
		let response;

		try {
			setFetchNetworksPending(true);
			response = await fetchTerminalNetworks(params);
		} catch (error) {
			dispatch(addErrorAsync({ slice: 'wmbusDevice', error }));
		}

		setFetchNetworksPending(false);
		return response;
	};

	const handleNetworkChange = (option) => setSelectedNetwork(option);

	useEffect(() => {
		return () => {
			setSelectedNetwork(null);
		};
	}, []);

	useEffect(() => {
		if (fetchNetworksPending) return;

		if (!deviceId || deviceId == guidEmpty) return;
		const equalDeviceId = isEqual(deviceId, previousDeviceId);

		if (equalDeviceId) return;

		dispatch(fetchNetworksAsync({ deviceId })).then((res) => {
			const networks = res?.networks?.map((opt) => ({
				value: opt.id,
				label: opt.name
			}));
			setNetworks(networks);

			if (networks && networks.length > 0) {
				if (selectedNetwork) {
					let selected = false;

					for (var i = 0; i < networks.length; i++) {
						if (isEqual(networks[i].value, selectedNetwork.value)) {
							selected = true;
							setSelectedNetwork(networks[i]);
							break;
						}
					}
					if (!selected) {
						setSelectedNetwork(networks[0]);
					}
				} else {
					setSelectedNetwork(networks[0]);
				}
			} else {
				setSelectedNetwork(null);
			}
		});
	}, [deviceId]);

	if (fetchNetworksPending) return <BaseLinearProgress pending={fetchNetworksPending} />;
	if (!selectedNetwork) return <EmptyPlaceholder text='Wmbus_DeviceInNetworkDiagnosticEmpty' fullHeight />;
	return (
		<Box>
			<Box display='flex' flexDirection='row'>
				<Box display='flex' flex={1} flexDirection='row' alignItems='center'>
					<Typography variant='h6'>{translate('Wmbus_DiagnosticOfDeviceCommunicationInNetwork')}</Typography>
					<Box ml={1}>
						<CustomSelectInlineText
							width={180}
							height={30}
							options={networks}
							value={selectedNetwork}
							onChange={handleNetworkChange}
							name='DeviceConfigurator_ActionSelectMore'
						/>
					</Box>
				</Box>
				<Box>
					<BaseButton
						variant='outlined'
						name='goToNetworkPage'
						onClick={() => navigate(`/wmbusnetworks/${selectedNetwork.value}`)}>
						Wmbus_CheckNetworkSettings
					</BaseButton>
				</Box>
			</Box>
			<DeviceDiagnosticReadsTimeline deviceId={deviceId} networkId={selectedNetwork.value} />
			<TerminalDiagnosticReadsList deviceId={deviceId} networkId={selectedNetwork.value} />
		</Box>
	);
};

export default TerminalDiagnostic;

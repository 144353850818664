import { Box, Typography } from '@mui/material';
import { TableListColumn, TableListItem } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';

const AddressesListItem = ({ title, description, domain, ip, port = '' }) => {
	const { translate } = useLocale();
	const domainAddress = domain ? `${domain}:${port}` : '';
	const ipAddress = ip ? `${ip}:${port}` : '';

	return (
		<TableListItem rolistItemProps={{ 'data-at': 'addressesListItem' }}>
			{() => (
				<Box display='flex' alignItems='center'>
					<TableListColumn flex data-at='addressesListItemName' disableLeftPadding>
						<Typography variant='body1'>{translate(title)}</Typography>
						<Typography variant='caption' color='textSecondary'>
							{translate(description)}
						</Typography>
					</TableListColumn>
					<TableListColumn
						width='auto'
						disableLeftPadding
						sx={{ flex: 'none' }}
						data-at='addressesListItemNameDomain'>
						<Typography variant='body1' width='fit-content'>
							{domainAddress}
						</Typography>
					</TableListColumn>
					<TableListColumn width={150} data-at='addressesListItemNameIp' disableLeftPadding>
						<Typography variant='body1'>{ipAddress}</Typography>
					</TableListColumn>
				</Box>
			)}
		</TableListItem>
	);
};

export default AddressesListItem;

import { TableListColumn, TableListHeader } from '@sonar-web/common';
import TableListColumnHeader from '@sonar-web/common/src/components/TableList/TableListColumnHeader';

const ServersAddressesListHeader = () => {
	return (
		<TableListHeader id='ServersAddressesListHeader'>
			<TableListColumn flex sx={{ textAlign: 'right' }} disableLeftPadding>
				<TableListColumnHeader text='DomainAddress' />
			</TableListColumn>
			<TableListColumn width={150} disableRightPadding sx={{ textAlign: 'right' }}>
				<TableListColumnHeader text='IpAddress' />
			</TableListColumn>
		</TableListHeader>
	);
};

export default ServersAddressesListHeader;

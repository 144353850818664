import { Container, Dialog } from '@mui/material';

const PanelContentDialog = ({ children, open, setOpen }) => {
	const licenseNotifier = document.getElementById('LicenseNotifier');
	const licenseNotifierHidden = licenseNotifier.classList.contains('MuiCollapse-hidden');

	const handleClose = () => setOpen(false);

	return (
		<Dialog
			open={open}
			onClose={(_, reason) => {
				if (reason === 'backdropClick') return;
				handleClose();
			}}
			fullWidth
			PaperProps={{
				elevation: 0,
				sx: {
					margin: 0,
					padding: '2rem',
					height: `calc(100% - ${licenseNotifierHidden ? 115 : 160}px)`,
					marginTop: licenseNotifierHidden ? '100px' : '145px',
					maxWidth: '100%',
					width: '100%'
				}
			}}
			slotProps={{
				root: {
					width: '100%'
				},
				backdrop: {
					invisible: true
				}
			}}>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					padding: '0px !important'
				}}>
				{children(setOpen)}
			</Container>
		</Dialog>
	);
};

export default PanelContentDialog;

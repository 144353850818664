/* eslint-disable react/display-name */
import { useEffect, useState, forwardRef, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import ConverterDiagnosticListItem from './ConverterDiagnosticListItem';
import { ListActionToolbar, BaseLinearProgress, BaseCircularProgress, PanelList } from '@sonar-web/common';
import { useFilterDescriptor } from '@sonar-web/common/src/hooks';
import { fetchThunk, setOffset } from '@sonar-web/common/src/features/List/genericListSlice';
import { fetchDiagnosticConverterReads } from '../converterDiagnosticApi';
import useList from '@sonar-web/common/src/features/List/useList';
import useListSorting from '@sonar-web/common/src/components/Sorters/useListSorting';
import FilterType from '@sonar-web/common/src/enums/FilterType';
import FilterOperator from '@sonar-web/common/src/enums/FilterOperator';
import EmptyPlaceholder from '@sonar-web/common/src/components/EmptyPlaceholder/EmptyPlaceholder';
import { slices } from '../../../Constants/Module';

const slice = slices.converterDiagnostic;

const ConverterDiagnosticList = ({ deviceId }) => {
	const dispatch = useDispatch();
	const { listSelector } = useList(slice);
	const { createFilter } = useFilterDescriptor();
	const virtuoso = useRef(null);

	const [isReloading, setIsReloading] = useState(true);

	const { elements, hasMore, pending, totalCount } = listSelector;
	const elementsCount = elements?.length;

	const fetchData = (append = false) => {
		if (!append) virtuoso.current?.scrollToIndex(0);
		else setIsReloading(!append);

		const defaultFilters = createFilter([
			{
				member: { name: 'converterId', type: FilterType.Guid.name },
				value: deviceId,
				operator: FilterOperator.Equal.value
			}
		]);

		dispatch(setOffset(slice)(append));
		dispatch(fetchThunk(slice)({ endpoint: fetchDiagnosticConverterReads, payload: { defaultFilters } }));
	};

	useListSorting({ slice, callback: fetchData });

	useEffect(() => {
		fetchData();
	}, []);

	if (!elements) return null;
	return (
		<>
			<ListActionToolbar slice={slice} pending={pending} sorters={sorters} totalCount={totalCount} />
			<BaseLinearProgress pending={pending && isReloading} />
			<Virtuoso
				ref={virtuoso}
				customScrollParent={document.getElementById('contentWrapper')}
				data={elements}
				context={{ pending, isReloading }}
				components={{ List, EmptyPlaceholder: Placeholder, Footer }}
				itemContent={ListItem}
				totalCount={elementsCount}
				endReached={() => {
					if (hasMore) fetchData(true);
				}}
			/>
		</>
	);
};

export default ConverterDiagnosticList;

const List = forwardRef(({ style, children }, listRef) => {
	return (
		<PanelList id='ConverterDiagnosticList' ref={listRef} style={style} disablePadding>
			{children}
		</PanelList>
	);
});

const ListItem = (_, item) => <ConverterDiagnosticListItem row={item} />;

const Placeholder = () => <EmptyPlaceholder text='Stationary_ConverterDiagnosticEmpty' />;

const Footer = ({ context }) => {
	const { pending, isReloading } = context;
	return <BaseCircularProgress pending={pending && !isReloading} />;
};

const sorters = {
	initial: 0,
	sorterData: [
		{
			member: 'date',
			text: 'Stationary_ReadDate',
			direction: 'desc',
			directionText: 'Common_Latest'
		},
		{
			member: 'date',
			text: 'Stationary_ReadDate',
			direction: 'asc',
			directionText: 'Common_Oldest'
		}
	]
};

import { Typography, Box } from '@mui/material';
import { TableListItem, formatDateTimeSecondsPrecision } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';
import RssiAfcData from './TransmissionData/RssiAfcData';

const TerminalDiagnosticReadsListItem = ({ row }) => {
	const { translate } = useLocale();
	const noTransmissionData = !row.transmissionData || row.transmissionData.length == 0;
	const readStatusText = row.isSuccessfullRead ? 'Wmbus_DiagnosticReadSuccessull' : 'Wmbus_DiagnosticReadFailed';

	return (
		<TableListItem
			classNames={classNameByStatus(row.isSuccessfullRead)}
			listItemProps={{
				sx: {
					'&.tablelistitem': {
						borderLeftWidth: '5px',
						borderLeftStyle: 'solid',
						borderLeftColor: 'border.main',
						'&.status_connected': {
							borderLeftColor: 'success.main'
						},
						'&.status_disconnected': {
							borderLeftColor: 'error.main'
						}
					}
				}
			}}>
			<>
				<Typography variant='body2'>
					{formatDateTimeSecondsPrecision(row.terminalReadDate ?? row.networkReadDate)}{' '}
					{translate(readStatusText)}
				</Typography>
				{noTransmissionData ? <NoDiagnosticData /> : <DiagnosticData row={row} />}
			</>
		</TableListItem>
	);
};
export default TerminalDiagnosticReadsListItem;

const classNameByStatus = (isSuccessfullRead) => `status_${isSuccessfullRead ? 'connected' : 'disconnected'}`;

const NoDiagnosticData = () => {
	const { translate } = useLocale();

	return (
		<Typography variant='body2' color='textSecondary'>
			{translate('Devices_DeviceNoTramsissionDataText')}
		</Typography>
	);
};

const DiagnosticData = ({ row }) => {
	const transmissionData = useParseTransmissionData(row.transmissionData);

	return (
		<Box display='flex' flexDirection='row' flexWrap='wrap'>
			{transmissionData?.map((item, index) => {
				return (
					<Box display='flex' flexDirection='row' key={index + 1}>
						{item.deviceLabel && <Typography variant='body2'>{item.deviceLabel}</Typography>}
						{item.rssi == undefined && item.afc == undefined ? null : (
							<RssiAfcData rssi={item.rssi} afc={item.afc} />
						)}
					</Box>
				);
			})}
		</Box>
	);
};

const useParseTransmissionData = (transmissionData) => {
	const { translate } = useLocale();

	const parsedData = JSON.parse(transmissionData).sort((a, b) => a.Order - b.Order);

	let data = [];

	for (let i = 0; i < parsedData.length; i++) {
		let item = {};

		if (i == 0) {
			item.deviceLabel = translate('Devices_Device');
		} else {
			item.deviceLabel = `${translate('Devices_Retransmitter')} (${parsedData[i - 1].RaField})`;
		}

		item.rssi = parsedData[i].Rssi;
		item.afc = parsedData[i].Afc;

		data.push(item);
	}

	data.push({
		deviceLabel: translate('Devices_Concentrator')
	});

	return data;
};

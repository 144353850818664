import { createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchTcpDevice, addTcpDevice, editTcpDevice, deleteTcpDevice } from './tcpDeviceApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { guidEmpty } from '@sonar-web/common';
import { tcpInfoReducers, tcpInfoInitial } from './TcpSummaries/tcpInfoSlice';
import { tcpStateReducers, tcpStateInitial } from './TcpSummaries/tcpStateSlice';

const slice = 'tcpDevice';
const getTcpDevice = (getState) => getState().tcpDevice.tcpDevice;

const initialTcpDeviceData = {
	id: guidEmpty,
	number: '',
	deviceTypeId: '',
	deviceTypeName: ''
};

export const tcpDeviceSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		addSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		deletePending: false,
		deleteSuccess: false,
		tcpDevice: initialTcpDeviceData,
		tcpInfo: tcpInfoInitial,
		tcpState: tcpStateInitial
	},
	reducers: {
		fetchTcpDeviceSucces: (state, action) => {
			state.tcpDevice = action.payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchTcpDevicePending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		addTcpDeviceSucces: (state, action) => {
			state.addPending = false;
			state.addSuccess = action.payload;
		},
		addTcpDevicePending: (state, action) => {
			state.addPending = action.payload ?? true;
		},
		deleteTcpDeviceSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteTcpDevicePending: (state, action) => {
			state.deletePending = action.payload ?? true;
		},
		setTcpDeviceData: (state, action) => {
			state.tcpDevice = action.payload;
		},
		resetTcpDeviceData: (state, action) => {
			const { isInSubmitManyMode } = action.payload || {};

			state.tcpDevice = isInSubmitManyMode
				? {
						...initialTcpDeviceData,
						deviceTypeId: state.tcpDevice.deviceTypeId,
						deviceTypeName: state.tcpDevice.deviceTypeName
					}
				: initialTcpDeviceData;
			state.addSuccess = false;
			state.deleteSuccess = false;
			state.fetchSuccess = false;
		},
		resetAddSuccess: (state) => {
			state.addSuccess = false;
		},
		...tcpInfoReducers,
		...tcpStateReducers
	}
});

export const {
	fetchTcpDeviceSucces,
	fetchTcpDevicePending,
	addTcpDeviceSucces,
	addTcpDevicePending,
	deleteTcpDeviceSucces,
	deleteTcpDevicePending,
	setTcpDeviceData,
	resetTcpDeviceData,
	resetAddSuccess
} = tcpDeviceSlice.actions;

export const fetchTcpDeviceAsync = (id) => async (dispatch) => {
	let response;

	try {
		dispatch(fetchTcpDevicePending());
		response = await fetchTcpDevice(id);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchTcpDeviceSucces(response));
};

export const addTcpDeviceAsync = () => async (dispatch, getState) => {
	const tcpDevice = { ...getTcpDevice(getState) };
	let response;
	let result = Object.assign({}, tcpDevice);

	delete tcpDevice.deviceTypeName;

	try {
		dispatch(addTcpDevicePending());

		response = await addTcpDevice(tcpDevice);
	} catch (error) {
		dispatch(addTcpDevicePending(false));

		const errorResult = await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));

		return errorResult;
	}

	result.id = response.id;

	dispatch(addTcpDeviceSucces(response.id ? result : true));
};

export const editTcpDeviceAsync = () => async (dispatch, getState) => {
	const tcpDevice = getTcpDevice(getState);

	try {
		dispatch(addTcpDevicePending());
		await editTcpDevice(tcpDevice);
	} catch (error) {
		dispatch(addTcpDevicePending(false));

		const errorResult = await dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));

		return errorResult;
	}

	dispatch(addTcpDeviceSucces(true));
};

export const deleteTcpDeviceAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteTcpDevicePending());
		await deleteTcpDevice(id);
		dispatch(deleteTcpDeviceSucces());
	} catch (error) {
		dispatch(deleteTcpDevicePending(false));
		return await dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectTcpDevice = createSelector(
	(state) => state[slice],
	(stateSlice) => ({
		dataRow: stateSlice.tcpDevice,
		fetchPending: stateSlice.fetchPending,
		fetchSuccess: stateSlice.fetchSuccess
	})
);

export const selectTcpDevicePending = createSelector(
	(state) => state[slice],
	(stateSlice) => stateSlice.addPending
);

export const selectTcpDeviceAddSuccess = createSelector(
	(state) => state[slice],
	(stateSlice) => stateSlice.addSuccess
);

export const selectDeleteTcpDevicePending = createSelector(
	(state) => state[slice],
	(stateSlice) => ({
		deletePending: stateSlice.deletePending,
		deleteSuccess: stateSlice.deleteSuccess
	})
);

export default tcpDeviceSlice.reducer;

export const moduleName = 'stationary';

export const slices = {
	stationaryNetworks: 'stationaryNetworks',
	stationaryNetwork: 'stationaryNetwork',
	converterDiagnostic: 'converterDiagnostic',
	terminalDiagnostic: 'terminalDiagnostic'
};

const Module = {
	moduleName,
	slices
};

export default Module;

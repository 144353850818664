import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { apiBase, BaseCircularProgress, ListNoRecords } from '@sonar-web/common';
import TemplateListItem from './TemplateListItem';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		background: '#FFFFFF',
		border: `1px solid ${theme.palette.border.main}`,
		boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.03)',
		borderRadius: 8,
		padding: theme.spacing(2),
		marginTop: theme.spacing(1),
		transition: 'box-shadow 300ms ease',
		'&:hover': {
			boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)'
		}
	}
}));

const TemplatesList = ({ template, setTemplate, configuratorData, props }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [elements, setElements] = useState(null);
	const [pending, setPending] = useState(false);

	const handleClick = (rec) => {
		if (!template || template.id !== rec.id) setTemplate(rec);
	};

	const fetchTemplates = async () => {
		try {
			setPending(true);
			const result = await fetch(props, configuratorData);

			setElements(result[props.templatesFetchEndpointResultObjectName]);
		} catch (error) {
			dispatch(addErrorAsync({ slice: 'configurator', error }));
		}
		setPending(false);
	};

	useEffect(() => {
		fetchTemplates();
	}, []);

	return (
		<div className={clsx(classes.root)}>
			<List disablePadding style={{ width: '100%' }}>
				{pending && <BaseCircularProgress pending={pending} />}
				{!pending && elements?.length === 0 && <ListNoRecords show={true} text='Common_TemplatesListEmpty' />}
				{!pending &&
					elements?.length > 0 &&
					elements.map((elem) => {
						return (
							<TemplateListItem
								key={elem.id}
								record={elem}
								selected={template?.id === elem.id}
								onClick={handleClick}
								props={props}
								setElements={setElements}
							/>
						);
					})}
			</List>
		</div>
	);
};

export default TemplatesList;

async function fetch(props, configuratorData) {
	const { servicePath, templatesFetchEndpoint, templatesFetchEndpointPathParams } = props;

	let url = templatesFetchEndpoint;

	if (templatesFetchEndpointPathParams?.length > 0)
		templatesFetchEndpointPathParams.forEach((ep) => {
			url = url.replace(`{${ep}}`, `${configuratorData[ep]}`);
		});

	const { data } = await apiBase(url, 'get', {
		path: servicePath,
		params: configuratorData.metadata ? { metadata: JSON.stringify(configuratorData.metadata) } : {}
	});

	return data;
}

import styled from '@emotion/styled';
import AddressesListItem from './AddressesListItem';
import ServersAddressesListHeader from './ServersAddressesListHeader';

const List = styled('ul')({
	padding: 0,
	margin: 0,
	'& li:first-of-type': {
		marginTop: 0
	}
});

const AddressesList = ({ data }) => {
	return (
		<>
			<ServersAddressesListHeader />
			<List>
				<AddressesListItem
					title='Converters'
					description='ServersAddressesConvertersDescription'
					domain={data.domainAddress}
					ip={data.ipAddress}
					port={data.wmbus}
				/>
				<AddressesListItem
					title='GsmDevices'
					description='ServersAddressesGsmDevicesDescription'
					domain={data.domainAddress}
					ip={data.ipAddress}
					port={data.tcp}
				/>
				<AddressesListItem
					title='Common_MeshTerminalDevices'
					description='ServersAddressesMeshDevicesDescription'
					domain={data.domainAddress}
					ip={data.ipAddress}
					port={data.mesh}
				/>
				<AddressesListItem
					title='Common_WmbusHubDevices'
					description='ServersAddressesWmbusHubDevicesDescription'
					domain={data.domainAddress}
					ip={data.ipAddress}
					port={data.wmbusHub24}
				/>
			</List>
		</>
	);
};

export default AddressesList;

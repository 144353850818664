export const WMBUS_HUB_DEVICE_TYPE = 'HUB-WMBUS';
export const WMBUS_HUB_PATTERN = /^\d{1,8}$/;

export const MESSAGES = {};

export const WMBUS_HUB_TABS = {
	SUMMARY: 'summary',
	EVENTS: 'events',
	DIAGNOSTICS: 'diagnostics',
	KNOWN_DEVICES: 'knownDevices',
	UNKNOWN_DEVICES: 'unknownDevices'
};

export const ROUTES = {
	wmbushubs: () => '/wmbushubs',
	wmbushub: (id) => (id ? `/wmbushubs/${id}` : '/wmbushubs/:id'),
	wmbushub_add: () => '/wmbushubs/add',
	wmbushub_known_devices: (id) => `/wmbushubs/${id}?tab=${WMBUS_HUB_TABS.KNOWN_DEVICES}`,
	wmbushub_unknown_devices: (id) => `/wmbushubs/${id}?tab=${WMBUS_HUB_TABS.UNKNOWN_DEVICES}`,
	wmbus_device: (id) => `/wmbusdevices/${id}`
};

export const SLICES = {
	WMBUS_HUBS: 'wmbusHubs',
	WMBUS_HUB: 'wmbusHub',
	WMBUS_HUB_DIAGNOSTIC: 'wmbusHubDiagnostic',
	WMBUS_HUB_DIAGNOSTIC_TIMELINE: 'wmbusHubDiagnosticTimeline',
	WMBUS_HUB_KNOWN_DEVICES: 'wmbusHubKnownDevices',
	WMBUS_HUB_UNKNOWN_DEVICES: 'wmbusHubUnknownDevices'
};

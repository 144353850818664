import Features from '../Enums/Features';
import Role from '../Enums/Role';

const admin = Role.Administrator.name;
const operator = Role.NetworkOperator.name;
const service = Role.CustomerService.name;
const exec = Role.ExecutiveAdministrator.name;

export default {
	[Features.EntryPage.name]: {
		roles: [admin, operator, service, exec]
	},
	[Features.DashboardPage.name]: {
		roles: [operator, service]
	},
	[Features.EventsPage.name]: {
		roles: [operator, service]
	},
	[Features.ReportsPage.name]: {
		roles: [operator, service, exec]
	},
	[Features.DataExchangePage.name]: {
		roles: [operator, service]
	},
	[Features.DeviceTypesPage.name]: {
		roles: [operator]
	},
	[Features.AccessPage.name]: {
		roles: [admin]
	},
	[Features.Settings.name]: {
		roles: [admin]
	},
	[Features.WatermeterPage.name]: {
		roles: [operator, service]
	},
	[Features.HeatallocatorPage.name]: {
		roles: [operator, service]
	},
	[Features.LocationNodePage.name]: {
		roles: [operator, service, exec]
	},
	[Features.DevicePage.name]: {
		roles: [operator, service]
	},
	[Features.TcpDevicePage.name]: {
		roles: [operator, service]
	},
	[Features.Imports.name]: {
		roles: [operator]
	},
	[Features.ImportAES.name]: {
		roles: [operator]
	},
	[Features.ImportWmbus.name]: {
		roles: [operator]
	},
	[Features.ImportHeatallocator.name]: {
		roles: [operator]
	},
	[Features.ImportWmbusWizard.name]: {
		roles: [operator]
	},
	[Features.ImportHeatallocatorWizard.name]: {
		roles: [operator]
	},
	[Features.ExportsPage.name]: {
		roles: [operator, service]
	},
	[Features.ExportConfigurator.name]: {
		roles: [operator, service]
	},
	[Features.NetworksPage.name]: {
		roles: [operator, service]
	},
	[Features.NetworkPage.name]: {
		roles: [operator, service]
	},
	[Features.LoraDevicePage.name]: {
		roles: [operator, service]
	},
	[Features.ReportPage.name]: {
		roles: [operator, service, exec]
	},
	[Features.ReportConfigurator.name]: {
		roles: [operator, exec]
	},
	[Features.HubPage.name]: {
		roles: [operator]
	},
	[Features.HubAdd.name]: {
		roles: [operator]
	},
	[Features.HubEdit.name]: {
		roles: [operator]
	},
	[Features.HubDelete.name]: {
		roles: [operator]
	},
	[Features.MeshNetworksPage.name]: {
		roles: [operator]
	},
	[Features.MeshNetworkPage.name]: {
		roles: [operator]
	},
	[Features.MeshTerminalPage.name]: {
		roles: [operator]
	},
	[Features.RoutingTablesPage.name]: {
		roles: [operator]
	},
	[Features.MeshTransmitsPage.name]: {
		roles: [operator]
	},
	[Features.MeshTransmitPage.name]: {
		roles: [operator]
	},
	[Features.MeshTransmitModification.name]: {
		roles: [operator]
	},
	[Features.MeshTransmitAdd.name]: {
		roles: [operator]
	},
	[Features.SystemSearch.name]: {
		roles: [operator, service]
	},
	[Features.LocationNodeModification.name]: {
		roles: [operator]
	},
	[Features.MeasurementSetAdd.name]: {
		roles: [operator]
	},
	[Features.MeasurementSetModification.name]: {
		roles: [operator]
	},
	[Features.TcpModification.name]: {
		roles: [operator]
	},
	[Features.TcpConfiguration.name]: {
		roles: [operator]
	},
	[Features.WatermeterModification.name]: {
		roles: [operator]
	},
	[Features.WmbusModification.name]: {
		roles: [operator]
	},
	[Features.HeatallocatorModification.name]: {
		roles: [operator]
	},
	[Features.ReportAdd.name]: {
		roles: [operator, exec]
	},
	[Features.ReportModification.name]: {
		roles: [operator, exec]
	},
	[Features.ReportGenerate.name]: {
		roles: [operator, exec]
	},
	[Features.NetworkAdd.name]: {
		roles: [operator]
	},
	[Features.NetworkModification.name]: {
		roles: [operator]
	},
	[Features.NetworkRun.name]: {
		roles: [operator]
	},
	[Features.NetworkConverter.name]: {
		roles: [operator]
	},
	[Features.NetworkDevices.name]: {
		roles: [operator]
	},
	[Features.NetworkSchedule.name]: {
		roles: [operator]
	},
	[Features.HeatMeterPage.name]: {
		roles: [operator, service]
	},
	[Features.HeatMeterModification.name]: {
		roles: [operator]
	},
	[Features.ImportHeatmeter.name]: {
		roles: [operator]
	},
	[Features.ImportHeatmeterWizard.name]: {
		roles: [operator]
	},
	[Features.UserPage.name]: {
		roles: [admin]
	},
	[Features.UserAdd.name]: {
		roles: [admin]
	},
	[Features.UserModification.name]: {
		roles: [admin]
	},
	[Features.GroupPage.name]: {
		roles: [admin]
	},
	[Features.GroupAdd.name]: {
		roles: [admin]
	},
	[Features.GroupModification.name]: {
		roles: [admin]
	},
	[Features.Billing.name]: {
		roles: [service]
	},
	[Features.OrdersPage.name]: {
		roles: [operator]
	},
	[Features.OrdersAndOrdersPacksPage.name]: {
		roles: [operator, exec]
	},
	[Features.OrderPage.name]: {
		roles: [operator, exec]
	},
	[Features.OrderAdd.name]: {
		roles: [operator, exec]
	},
	[Features.OrderTaskPage.name]: {
		roles: [operator, exec]
	},
	[Features.DeviceConfigurationsPage.name]: {
		roles: [operator, exec]
	},
	[Features.DeviceConfigurationPage.name]: {
		roles: [operator, exec]
	},
	[Features.NetworkReadCancel.name]: {
		roles: [operator, exec]
	},
	[Features.SynchronizeUsers.name]: {
		roles: [admin]
	},
	[Features.HubConfiguration.name]: {
		roles: [operator]
	},
	[Features.MeshNetworkPage.name]: {
		roles: [operator]
	},
	[Features.MeshNetworkAdd.name]: {
		roles: [operator]
	},
	[Features.MeshNetworkModification.name]: {
		roles: [operator]
	},
	[Features.MeshTerminalModification.name]: {
		roles: [operator]
	},
	[Features.MeshRoutingTableDownload.name]: {
		roles: [operator]
	},
	[Features.MeshTransmitsAdd.name]: {
		roles: [operator]
	},
	[Features.MeshTransmitsModification.name]: {
		roles: [operator]
	},
	[Features.MeshHubFirmwareUpdate.name]: {
		roles: [operator]
	},
	[Features.MultiOperationsPage.name]: {
		roles: [operator]
	},
	[Features.WaterMeterMetaData.name]: {
		roles: [operator]
	},
	[Features.HeatmeterMetaData.name]: {
		roles: [operator]
	},
	[Features.ProtocolTemplatePage.name]: {
		roles: [operator]
	},
	[Features.CustomersModification.name]: {
		roles: [operator, service]
	},
	[Features.ContractorsPage.name]: {
		roles: [operator, exec]
	},
	[Features.ContractorsAdd.name]: {
		roles: [operator, exec]
	},
	[Features.ContractorsModification.name]: {
		roles: [operator, exec]
	},
	[Features.OrderedOrdersPacks.name]: {
		roles: [operator]
	},
	[Features.ReceivedOrdersPacks.name]: {
		roles: [exec]
	},
	[Features.OrdersPackPage.name]: {
		roles: [exec]
	},
	[Features.OrdersPackPageSimple.name]: {
		roles: [operator, exec]
	},
	[Features.ImportsPage.name]: {
		roles: [operator]
	},
	[Features.PointsGroupsPage.name]: {
		roles: [operator]
	},
	[Features.PointsGroupPage.name]: {
		roles: [operator]
	},
	[Features.PointAdditionalParameters.name]: {
		roles: [operator, service]
	},
	[Features.ReadsModification.name]: {
		roles: [operator, service]
	},
	[Features.WmbusHubsPage.name]: {
		roles: [operator]
	},
	[Features.WmbusHubPage.name]: {
		roles: [operator]
	},
	[Features.WmbusHubAdd.name]: {
		roles: [operator]
	},
	[Features.WmbusHubModification.name]: {
		roles: [operator]
	}
};

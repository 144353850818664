import { Box } from '@mui/material';
import ConverterDiagnosticList from './DiagnosticReadsList/ConverterDiagnosticList';
import DeviceDiagnosticReadsTimeline from './DiagnosticReadsTimeline/DeviceDiagnosticReadsTimeline';

const ConverterDiagnostic = ({ deviceId }) => {
	return (
		<Box display='flex' flexDirection='column' height='100%'>
			<DeviceDiagnosticReadsTimeline deviceId={deviceId} />
			<ConverterDiagnosticList deviceId={deviceId} />
		</Box>
	);
};

export default ConverterDiagnostic;

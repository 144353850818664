import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import WmbusHubsService from '../../Services/WmbusHubsService';
import { SLICES } from '../Constants';

const slice = SLICES.WMBUS_HUB_DIAGNOSTIC_TIMELINE;
export const wmbusHubDiagnosticTimelineSlice = createSlice({
	name: slice,
	initialState: {
		pending: false,
		reset: false,
		reads: [],
		firstEntryDate: null,
		lastEntry: null
	},
	reducers: {
		fetchHubDiagnosticSucces: (state, { payload }) => {
			state.reads = payload.elements;
			state.firstEntryDate = payload.firstEntryDate;
			state.lastEntry = payload.lastEntry;
			state.pending = false;
			state.reset = false;
		},
		fetchHubDiagnosticPending: (state, { payload }) => {
			state.pending = payload;
		},
		setResetAndReload: (state) => {
			state.reset = true;
		}
	}
});

export const { fetchHubDiagnosticSucces, fetchHubDiagnosticPending, setResetAndReload } =
	wmbusHubDiagnosticTimelineSlice.actions;

export const fetchHubDiagnosticAsync = (pageDescriptor) => async (dispatch) => {
	try {
		dispatch(fetchHubDiagnosticPending(true));
		const response = await WmbusHubsService.fetchHubDiagnostic(pageDescriptor);
		dispatch(fetchHubDiagnosticSucces(response));
	} catch (error) {
		dispatch(fetchHubDiagnosticPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectHubDiagnostic = createSelector(
	(state) => state.wmbusHubDiagnosticTimeline,
	(wmbusHubDiagnosticTimeline) => ({
		firstEntryDate: wmbusHubDiagnosticTimeline.firstEntryDate,
		lastEntry: wmbusHubDiagnosticTimeline.lastEntry,
		reads: wmbusHubDiagnosticTimeline.reads,
		pending: wmbusHubDiagnosticTimeline.pending,
		reset: wmbusHubDiagnosticTimeline.reset
	})
);

export default wmbusHubDiagnosticTimelineSlice.reducer;

/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import {
	filterOperators,
	addFilters,
	ListNoRecords,
	BaseLinearProgress,
	useSidebar,
	BaseCircularProgress,
	PanelList,
	removeAllSliceFilters,
	listScrollToElement
} from '@sonar-web/common';
import {
	selectLocationNodes,
	selectSelected,
	setSelected,
	fetchLocationNodesAsync,
	setOffset,
	setResetAndReload,
	selectSelectedBreadcrumb,
	resetLocationNodes
} from './slice';
import LocationNodesListItem from './LocationNodesListItem';
import { useFilterDescriptor, usePrevious } from '@sonar-web/common/src/hooks';
import { isEqual } from 'lodash-es';

const sidebarType = 'locationNodes';
const slice = 'locationNodes';

const LocationNodesList = ({
	listItem,
	asList = false,
	listDefaultFilters = [],
	additionMode,
	additionModeOnSelect
}) => {
	const dispatch = useDispatch();
	const { createFilter } = useFilterDescriptor();
	const { unloadSidebar } = useSidebar();
	const { dataRows, hasMore, pending, reset } = useSelector(selectLocationNodes);
	const [isReloading, setIsReloading] = useState(true);
	const selected = useSelector(selectSelected);
	const currentBreadcrumb = useSelector(selectSelectedBreadcrumb);

	const currentSorters = useSelector((state) => state.sorters[slice]);
	const previousSorters = usePrevious(currentSorters);
	const currentFilters = useSelector((state) => state.filters[slice]);
	const previousFilters = usePrevious(currentFilters);

	const fetchData = (append = false) => {
		if (append) {
			if (!hasMore) return;
		} else listScrollToElement(0, 'LocationNodesList');

		dispatch(setOffset(append));
		setIsReloading(!append);
		dispatch(fetchLocationNodesAsync());
	};

	const resetAndReload = (delay = false) => {
		const filtersData = asList
			? listDefaultFilters
			: createFilter([
					{
						member: { name: 'parentLocationNodeId', type: 'guid' },
						value: currentBreadcrumb.id,
						operator: filterOperators.equal
					}
				]);

		if (delay) {
			setTimeout(() => {
				dispatch(addFilters({ slice, filtersData }));
				dispatch(setResetAndReload());
			}, 200);
		} else {
			dispatch(addFilters({ slice, filtersData }));
			dispatch(setResetAndReload());
		}
	};

	useEffect(() => {
		if (selected) {
			dispatch(setSelected(null));
			unloadSidebar(sidebarType);
			resetAndReload(true);
			return;
		}

		resetAndReload();
	}, [currentBreadcrumb]);

	useEffect(() => {
		const noEqualSorters = asList ? true : !isEqual(currentSorters, previousSorters);
		const noEqualFilters = asList ? true : !isEqual(currentFilters, previousFilters);

		if (reset || noEqualSorters || noEqualFilters) fetchData();
	}, [reset, currentSorters, currentFilters]);

	useEffect(() => {
		return () => {
			unloadSidebar(sidebarType);
			dispatch(resetLocationNodes());
			dispatch(removeAllSliceFilters(slice));
		};
	}, []);

	if (!dataRows) return null;
	const hasRecords = dataRows && dataRows.length !== 0;

	const Components = {
		List: React.forwardRef(({ style, children, className }, listRef) => {
			return (
				<PanelList
					id='LocationNodesList'
					ref={listRef}
					style={style}
					disablePadding
					className={className}
					data-at-params={`hasLocations|${hasRecords}|hasMoreLocations|${hasMore}`}>
					{children}
				</PanelList>
			);
		}),
		Footer: () => <BaseCircularProgress pending={pending && !isReloading} paddingTop={3} />,
		EmptyPlaceholder: () => (
			<ListNoRecords
				id='LocationEmptyList'
				show={!pending && !hasRecords}
				text='LocationNodes_LocationNodeIsEmpty'
			/>
		)
	};

	const itemContent = (index, row) => {
		return listItem ? (
			listItem({ row, selected })
		) : (
			<LocationNodesListItem
				row={row}
				selected={selected}
				additionMode={additionMode}
				additionModeOnSelect={additionModeOnSelect}
			/>
		);
	};

	return (
		<>
			<BaseLinearProgress pending={pending && isReloading} />
			<Virtuoso
				style={{ minHeight: 225 }}
				data={dataRows}
				components={Components}
				itemContent={itemContent}
				totalCount={dataRows.length}
				endReached={() => fetchData(true)}
			/>
		</>
	);
};

export default LocationNodesList;
